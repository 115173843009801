import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Add } from 'grommet-icons';

const StyledAdd = styled(Add)`
  path {
    stroke-width: 3px;
  }
`;

const IconAdd = ({ size = '14px', ...rest }) => (
  <StyledAdd size={size} {...rest} />
);

IconAdd.propTypes = {
  size: PropTypes.string,
};

export default IconAdd;
