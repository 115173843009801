import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../lib/nodash';
import { Button, ButtonProps } from 'grommet';
import Loading from './Loading';
import IconAdd from './Icons/IconAdd';

import useAddToCart from './Cart/useAddToCart';

type CartAddLineItem = {
  variantId: string;
  quantity: number;
};

type Props = {
  variantId: string;
  size?: string;
  quantity?: number;
  label?: string;
  useIcon?: boolean;
  onAdded?: () => void;
  initialLoading?: boolean;
  onAddToCart?: (lineItems: CartAddLineItem[]) => void;
  secondary?: boolean;
} & ButtonProps;

const ButtonAddToCart = ({
  label = 'Add',
  variantId,
  quantity = 1,
  useIcon = true,
  size = 'small',
  onAdded,
  initialLoading = false,
  onAddToCart,
  ...rest
}: Props) => {
  const a2cAdd = useAddToCart().addToCart;
  const addToCart = onAddToCart ? onAddToCart : a2cAdd;
  const [loading, setLoading] = useState(initialLoading);
  const handleAdd = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    await addToCart([{ variantId, quantity }]);
    if (onAdded && isFunction(onAdded)) {
      onAdded();
    }
    setLoading(false);
  };

  return (
    <Button
      justify="between"
      primary={rest.secondary ? false : true}
      icon={loading ? <Loading /> : useIcon ? <IconAdd /> : undefined}
      label={label}
      size={size}
      reverse
      onClick={handleAdd}
      disabled={loading}
      {...rest}
    />
  );
};

ButtonAddToCart.propTypes = {
  variantId: PropTypes.string.isRequired,
  size: PropTypes.string,
  quantity: PropTypes.number,
  label: PropTypes.string,
  useIcon: PropTypes.bool,
  onAdded: PropTypes.func,
  initialLoading: PropTypes.bool,
  onAddToCart: PropTypes.func,
};

export default memo(ButtonAddToCart);
